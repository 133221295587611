/*
 *@Author: 段彩翔
 *@Date: 2022-04-12 13:50:55
 *@Description: 二维码页
*/
<template>
	<div class='displayFlex flexColumn h100'>
		<!-- 顶部 -->
		<NavbarVue color="white" backgroundColor="#FF9941" :showBack="true">MyInfo</NavbarVue>
		<!-- 内容 -->
		<div class='displayFlex flexColumn flexAlignCenter flexJCCenter h100'>
			<img src="../../../assets/img/myinfo.png" alt="" style="width: 260px;height: 100px">
			<img :src="qrCode" alt="" style="width: 256px;height: 256px;" class="mt20px">
			<p class="mt20px">Please assist client to scan this QR code (via client’s phone camera) to access the SingPass authorization page and perform the authorization process.</p>
			<p>Click on Next when client completes authorization process.</p>
			<el-button type="warning" size="medium" class="mt20px" @click="next">Next</el-button>
		</div>
	</div>
</template>

<script>

import NavbarVue from "@/components/Navbar.vue"
import { qrCodeImage, getPersonData } from "@/api/Home/index"
export default {
	components: { NavbarVue },
	data () {
		return {
			qrCode: '',
			random: ''
		}
	},
	computed: {},
	watch: {},
	methods: {
		async getQrCodeImage () {
			let random = Math.round(Math.random() * 100000000)
			this.random = random
			let params = {
				random: random,
				contentsUrl: 'https://fab-uat.infinitumfa.com/portal/declaration?' + random,
				width: '256',
				height: '256'
			}
			if (process.env.VUE_APP_TITLE == 'dev') {
				params.contentsUrl = 'http://localhost:3000/declaration?' + random
			}
			if (process.env.VUE_APP_TITLE == 'uat') {
				params.contentsUrl = 'https://fab-uat.infinitumfa.com/portal/declaration?' + random
			}
			if (process.env.VUE_APP_TITLE == 'prod') {
				params.contentsUrl = 'https://fab.infinitumfa.com/portal/declaration?' + random
			}

			let res = await qrCodeImage(params)
			this.qrCode = res.content.qrCode
			return undefined
		},
		async next () {
			let personData = await getPersonData({ uniqueId: this.random })
			if (personData.content) {
				personData = personData.content
				let kycParam = global.kycParam
				let person = kycParam.kyc.client[this.$router.currentRoute.query.index].personal_information
				person.full_name = personData.name
				person.surname = personData.aliasname
				person.nric_passport_fin = personData.uinfin
				person.contact_no_mobile_area = personData.mobileAreacode
				person.contact_no_mobile = personData.mobileno
				person.gender = personData.sex
				person.marial_status = personData.marital
				if (personData.race === 'CHINESE') {
					person.race = 'Chinese'
				} else if (personData.race === 'MALAY') {
					person.race = 'Malay'
				} else if (personData.race === 'INDIAN') {
					person.race = 'Indian'
				} else {
					person.race = 'Others'
					person.race_content = personData.race
				}
				person.date_of_birth = personData.dob
				person.mailing_street = personData.mailStreet
				person.mailing_unit_no = personData.mailUnit
				person.mailing_building_name = personData.mailBuilding
				person.mailing_country = personData.mailCountry
				person.mailing_zip_code = personData.mailPostal
				person.residential_street = personData.block + ' ' + personData.resStreet
				person.residential_unit_no = '#' + personData.floor + '-' + personData.resUnit
				person.residential_building_name = personData.resBuilding
				person.residential_country = personData.resCountry
				person.residential_zip_code = personData.resPostal
				person.occupation = personData.occupation
				person.email = personData.email
				person.nationality = personData.nationality
				this.$store.commit('SET_KYC_ALL_DATA', kycParam)
				this.$router.push('/BeforeStart?personData&index=' + this.$router.currentRoute.query.index)
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {

	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.getQrCodeImage()
	},
}
</script>
<style lang='css' scoped>
p {
	color: #707070;
	line-height: 32px;
}
</style>